import React from "react";
// import { useHistory } from "react-router-dom";

const Tooltip = (props) => (

  // const history = useHistory();

// function TooltipNav() {
//   if (props.label == "Home") {
//     history.push("/");
//   }
//   if (props.label == "Work") {
//     history.push("/work");
//   }
//   if (props.label == "About") {
//     history.push("/about");
//   }
//   if (props.label == "Contact") {
//     history.push("/contact");
//   }
// }

  <svg
    viewBox="0 0 1692.23 1692.23"
    {...props}
    className={props.className + " " + "tooltip-contain"}
  >
    <g className="tooltip">
      <g className="tooltip-G">
        <path
          className="tooltip-BG"
          fill="#fff"
          d="M922.56 76.45L884.34 76.45 846.11 0 807.89 76.45 769.66 76.45 616.77 76.45 616.77 229.35 769.66 229.35 922.56 229.35 1075.46 229.35 1075.46 76.45 922.56 76.45z"
        />
        <text className="tooltip-text">
          <tspan>{props.label}</tspan>
        </text>
      </g>
      <path
        className="tooltip-circle"
        d="M846.61.5C380.07.5.5 380.06.5 846.61s379.57 846.12 846.11 846.12 846.12-379.57 846.12-846.12S1313.16.5 846.61.5z"
        fill="none"
        stroke="none"
        strokeMiterlimit={10}
      />
    </g>
  </svg>
);

export default Tooltip;
