import React, { useRef, useMemo, useEffect } from "react";
import { gsap } from "gsap";
import { useCircularInputContext, useCircularDrag } from "react-circular-input";

const CircularThumbCustom = () => {

  const timeline = useMemo(() => gsap.timeline({ paused: true }), []);
  // const timelineB = useMemo(() => gsap.timeline({ paused: true }), []);
  const ref = useRef(null);

  useEffect(() => {
    timeline.to(ref.current, {
      // opacity: 0,
      stroke: "#ff9700",
      duration: 1.5,
      repeat:-1,
      // repeatDelay: 1,
      yoyo: true,
    });
    timeline.play();



  }, [timeline]);

      const circInputPause = () => {
        timeline.pause(0);
      // timelineB.to(ref.current, {
      //   stroke: "#000",
      //   duration: 1.5,
      //   repeat:-1,
      //   // repeatDelay: 1,
      //   yoyo: true,
      // }); 
      };

      const circInputPlay = () => {
        timeline.play(0);
      };

//   function rotate(cx, cy, x, y, angle) {
//     var radians = (Math.PI / 180) * angle,
//       cos = Math.cos(radians),
//       sin = Math.sin(radians),
//       nx = cos * (x - cx) + sin * (y - cy) + cx,
//       ny = cos * (y - cy) - sin * (x - cx) + cy;
//     console.log(nx);
//     console.log(ny);
//     return [nx, ny];
//   }

  const { getPointFromValue, center } = useCircularInputContext();
  const { x, y } = getPointFromValue();
//   const cx = center.x;
//   const cy = center.y;
//   const angle = -90;
//   var nx;
//   var ny;

//   rotate(cx, cy, x, y, angle);


  useCircularDrag(ref);
//   console.log(center);
//   console.log(cx);
//   console.log(cy);
//   console.log(nx);
//   console.log(ny);

  return (
    <line 
    // onMouseEnter={circInputPause} onMouseLeave={circInputPlay} 
    ref={ref} x1={x} y1={y} x2={x} y2={y} className="custom-thumb-new" />
  );
};

export default CircularThumbCustom;