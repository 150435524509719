import React, { useState, useRef, useLayoutEffect } from "react";
import { CircularInput, CircularTrack, CircularProgress, CircularThumb, useCircularInputContext, useCircularDrag } from "react-circular-input";
import useResizeObserver from '@react-hook/resize-observer'
import CircularThumbCustom from "./CircularThumbCustom";
import Tooltip from "./svg/Tooltip";
import { useHistory } from "react-router-dom";

const useSize = (target) => {
  const [size, setSize] = useState();
  useLayoutEffect(() => {
  setSize(target.current.getBoundingClientRect());
  }, [target]);
  useResizeObserver(target, (entry) => setSize(entry.contentRect));
  return size;
};

const DialMenu = () => {

const history = useHistory();
const min = 0;
const [value, setValue] = useState(0);
const [radius, setRadius] = useState(0);
const [inc, setInc] = useState(0);
const [max, setMax] = useState(1);
const [valHome, setValHome] = useState(0);
const [valWork, setValWork] = useState(0);
const [valAbout, setValAbout] = useState(0);
const [valContact, setValContact] = useState(0);

const target = useRef(null);
const size = useSize(target);
// const ref = useRef(null);
// useCircularDrag(ref);

// const { getPointFromValue } = useCircularInputContext();
// const { x, y } = getPointFromValue();

let arrange   
    arrange = setTimeout(resized, 1);
  function resized(){
  /* Portrait */
  if (window.matchMedia("screen and (min-aspect-ratio: 1/2000) and (max-aspect-ratio: 767/1100)").matches) {
    setRadius( size?.width / 2);
    setInc(12);
    setMax(0.25);
    setValHome(0);
    setValWork(0.08333333333333333);
    setValAbout(0.16666666666666666);
    setValContact(0.25);
  }
  /* tablet - Portrait */
  else if (window.matchMedia("screen and (min-aspect-ratio: 768/1100) and (max-aspect-ratio: 1/1)").matches) {
    setRadius( size?.width / 2);
    setInc(16);
    setMax(0.1875);
    setValHome(0);
    setValWork(0.0625);
    setValAbout(0.125);
    setValContact(0.1875);
  } 
     /* Desktop - Landscape */
  else if (window.matchMedia("screen and (min-aspect-ratio: 1/1) and (max-aspect-ratio: 640/390)").matches) {
    setRadius( size?.height / 2);
    setInc(8);
    setMax(0.375);
    setValHome(0);
    setValWork(0.125);
    setValAbout(0.25);
    setValContact(0.375);
  }   
  /* Mobile - Landscape */
  else if (window.matchMedia("screen and (min-aspect-ratio: 640/390) and (max-aspect-ratio: 1388/508)").matches) {
    setRadius( size?.height / 2);
    setInc(12);
    setMax(0.25);
    setValHome(0);
    setValWork(0.08333333333333333);
    setValAbout(0.16666666666666666);
    setValContact(0.25);
  }
  /* Desktop - Landscape - Slim */
  else if (window.matchMedia("screen and (min-aspect-ratio: 1388/508) and (max-aspect-ratio: 2320/1)").matches) {
    setRadius( size?.height / 2);
    setInc(12);
    setMax(0.25);
    setValHome(0);
    setValWork(0.08333333333333333);
    setValAbout(0.16666666666666666);
    setValContact(0.25);
  }
  }

let steppedValueWithinLimits = v => Math.min(Math.max((Math.round(v * inc) / inc), min),max)
console.log("value = " + value);

  function circDefault() {
    if (value == valHome) {
      history.push("/");
    }
    if (value == valWork) {
      history.push("/work");
    }
    if (value == valAbout) {
      history.push("/about");
    }
    if (value == valContact) {
      history.push("/contact");
    }
  }

  return ( <div className="target-wrap" ref={target} >
     <Tooltip className="home-tip" label="Home" />
      <Tooltip className="work-tip" label="Work" />
      <Tooltip className="about-tip" label="About" />
      <Tooltip className="contact-tip" label="Contact" />
<CircularInput className="menu-dial" value={steppedValueWithinLimits(value)} 
radius={radius} onChange={setValue} onChange={v => setValue(steppedValueWithinLimits(v))} onChangeEnd={circDefault}>
      <CircularTrack vector-effect="non-scaling-stroke" className="custom-track"/>
      <CircularProgress vector-effect="non-scaling-stroke" className="custom-progress"/>
      {/* <line ref={ref} x1={190} y1={100} x2={190} y2={100} className="custom-thumb-new" /> */}
    {/* <line ref={ref} x1={x} y1={y} x2={x} y2={y} className="custom-thumb-new" />  */}
      {/* <CircularThumb className="custom-thumb" /> */}
      <CircularThumbCustom className="custom-thumb-new" />
    </CircularInput>  
 
</div>  
  );
}

export default DialMenu;
